export function getTime(timestamp = '') { // 获取时间或将时间戳转化为时间格式（日期格式）后返回,timestamp为时间戳
  let date
  if (timestamp === '') {
    date = new Date()
  } else {
    timestamp = parseInt(timestamp)
    if (timestamp.toString().length === 10) { // timestamp 为10位时，需要*1000
      date = new Date(timestamp * 1000)
    } else {
      date = new Date(timestamp)
    }
  }
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  month = dateZeroFill(month)
  let day = date.getDate()
  day = dateZeroFill(day)
  let hour = date.getHours()
  hour = dateZeroFill(hour)
  let minute = date.getMinutes()
  minute = dateZeroFill(minute)
  let second = date.getSeconds()
  second = dateZeroFill(second)
  const busUpdateTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
  return busUpdateTime
}

export function formatDate(date, monthAdd = true) { // 将中国标准时间转化为时间对象格式； monthAdd 为true是代表转换后需要月份+1
  date = new Date(date)
  const year = date.getFullYear()
  let month = date.getMonth()
  if (monthAdd) {
    month = date.getMonth() + 1
  }
  month = dateZeroFill(month)
  let day = date.getDate()
  day = dateZeroFill(day)
  let hour = date.getHours()
  hour = dateZeroFill(hour)
  let minute = date.getMinutes()
  minute = dateZeroFill(minute)
  let second = date.getSeconds()
  second = dateZeroFill(second)
  date = {
    year,
    month,
    day,
    hour,
    minute,
    second
  }
  return date
}

export function timeStmapReset(timestamp) { // 将时间戳转化时间对象
  timestamp = parseInt(timestamp)
  let date = new Date(timestamp)
  if (timestamp.toString().length === 10) { // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    date = new Date(timestamp * 1000)
  }
  const year = date.getFullYear()
  let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  month = dateZeroFill(month)
  let day = date.getDate()
  day = dateZeroFill(day)
  let hour = date.getHours()
  hour = dateZeroFill(hour)
  let minute = date.getMinutes()
  minute = dateZeroFill(minute)
  let second = date.getSeconds()
  second = dateZeroFill(second)
  date = {
    year,
    month,
    day,
    hour,
    minute,
    second
  }
  return date
}

export function formatDateString(date, type, monthAdd) { // 将中国标准时间或时间戳转化为日期字符串 返回格式为:'2018-05-28 23:24:12'
  let dateObj = null
  if (type === 'formatDate') { // 中国标准时间
    dateObj = formatDate(date, monthAdd)
  } else if (type === 'timeStmapReset') { // 时间戳
    dateObj = timeStmapReset(date)
  } else {
    console.log('时间转化方法缺少type参数')
  }
  if (dateObj) {
    return dateObj.year + '-' + dateObj.month + '-' + dateObj.day + ' ' + dateObj.hour + ':' + dateObj.minute + ':' + dateObj.second
  }
}

function dateZeroFill(time) { // time为月或日、时、分、秒，若time只有一位则在前面补零
  return time.toString().length === 1 ? '0' + time.toString() : time
}

export function dateConvStamp(dateTime) { // 将日期转化为时间戳 dateTime格式：'2018-05-28 23:24:12'
  // 手机系统不支持2019-04-29这种格式因此需要.replace(/-/g, '/')来转化为2019/04/29这种格式
  return Date.parse(new Date(dateTime.replace(/-/g, '/')))
}

export function dateResetObj(dateTime) { // 将日期转化为时间对象 dateTime格式：'2018-05-28 23:24:12'
  return timeStmapReset(dateConvStamp(dateTime))
}

export function randomString(e, type = 'all') { // 生成 e 长度的随机字符串, type为随机的类型
  e = e || 32
  let t = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789'
  if (type === 'number') { // 数字
    t = '123456789'
  } else if (type === 'letters') { // 字母
    t = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  } else if (type === 'capitalLetters') { // 大写字母
    t = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  } else if (type === 'lowercaseLetters') { // 小写字母
    t = 'abcdefghijklmnopqrstuvwxyz'
  }

  const a = t.length
  let n = ''
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
  return n
}

export function setScreen(id, fullscreen) { // 设置后改变 id 对应的容器全屏 fullscreen 的状态
  const element = document.getElementById(id)
  if (!fullscreen) {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  } else {
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }
  }
  fullscreen = !fullscreen
  return fullscreen
}

export function strSetArr(str, spacer = '-') { // 将包含spacer的'值'的字符串,转化为以spacer的'值'为间隔的数组
  const num = (str.split(spacer)).length - 1
  const arr = []
  if (num === 0) {
    arr[0] = str
  } else {
    for (let i = 0; i < num; i++) {
      arr[i] = str.substring(0, str.indexOf(spacer))
      str = str.substring(arr[i].length + 1)
    }
    arr.push(str)
  }
  return arr
}


export function isIp(str) { // 字符串是否属于ip格式
  var regexp = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
  return function (value) {
    var valid = regexp.test(value);
    if (!valid) {//首先必须是 xxx.xxx.xxx.xxx 类型的数字，如果不是，返回false
      return false;
    }
    return value.split('.').every(function (num) {
      //切割开来，每个都做对比，可以为0，可以小于等于255，但是不可以0开头的俩位数
      //只要有一个不符合就返回false
      if (num.length > 1 && num.charAt(0) === '0') {
        //大于1位的，开头都不可以是‘0’
        return false;
      } else if (parseInt(num, 10) > 255) {
        //大于255的不能通过
        return false;
      }
      return true;
    })
  }
}


export function removeIpAndPort(url) { // 删除字符串中的ip和端口前缀
  const ipPortRegex = /(\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b):(\d{1,5})\b/ // 提取字符串中的ip的正则表达式
  const urlIpPortArr = url.match(ipPortRegex)
  const ipAndPort = urlIpPortArr[0]
  const pictrueUrl = url.slice(url.indexOf(ipAndPort) + ipAndPort.length)
  return pictrueUrl
}
