export const funcUL = [{
  title: "基本",
  icon: "assignment",
},
{
  title: "组件",
  icon: "apps",
},
{
  title: "数据",
  icon: "apps",
},
{
  title: "图库",
  icon: "photo_library",
},
{
  title: "交互",
  icon: "nature_people",
},
{
  title: "上传",
  icon: "cloud_upload",
},
];

export const LNoption = {
  base: {
    text_fields: "文本",
    date_range: "时间",
    link: "超链接",
    replay: "刷新",
    switch_video: "切换画布",
    web: "iframe",
    fullscreen: "全屏切换",
    camera: "截图",
    landscape_screen: "横屏"
  },
  module: {
    base: {
      name: "常用",
      list: [{
        title: "视频",
        id: "data-video",
        img: require("../assets/edit/base_video.svg"),
      },
      {
        title: "数据",
        id: "data-value",
        img: require("../assets/edit/base_data.svg"),
      },
      {
        title: "开关",
        id: "data-switch",
        img: require("../assets/edit/base_switch.svg"),
      },
      {
        title: "控制",
        id: "data-control",
        img: require("../assets/edit/base_control.svg"),
      },
      {
        title: "文本",
        id: "data-text",
        img: require("../assets/edit/base_text.svg"),
      },
      {
        title: "图片",
        id: "data-photo",
        img: require("../assets/edit/base_photo.svg"),
      },
      ],
    },
    curvechart: {
      name: "图表",
      list: [{
        title: "曲线图",
        id: "chart-curve",
        img: require("../assets/edit/chart_curve.svg"),
      },
      {
        title: "区域图",
        id: "chart-area",
        img: require("../assets/edit/chart_area.svg"),
      },
      {
        title: "柱状图",
        id: "chart-pillar",
        img: require("../assets/edit/chart_pillar.svg"),
      },
      {
        title: "饼状图",
        id: "chart-pie",
        img: require("../assets/edit/chart_pie.svg"),
      },
      {
        title: "仪表盘",
        id: "chart-meter",
        img: require("../assets/edit/chart_meter.svg"),
      },
      ],
    },
    table: {
      name: "列表",
      list: [{
        title: "二维表",
        id: "table-two",
        img: require("../assets/edit/table_two.svg"),
      },
      {
        title: "三维表",
        id: "table-three",
        img: require("../assets/edit/table_three.svg"),
      },
      ],
    },
  },
  dataValue: {
    base: {
      name: "常用",
      list: [{
        title: "视频",
        id: "data-video",
        img: require("../assets/edit/base_video.svg"),
      },
      {
        title: "数据",
        id: "data-value",
        img: require("../assets/edit/base_data.svg"),
      },
      {
        title: "开关",
        id: "data-switch",
        img: require("../assets/edit/base_switch.svg"),
      },
      {
        title: "控制",
        id: "data-control",
        img: require("../assets/edit/base_control.svg"),
      },
      {
        title: "文本",
        id: "data-text",
        img: require("../assets/edit/base_text.svg"),
      },
      {
        title: "图片",
        id: "data-photo",
        img: require("../assets/edit/base_photo.svg"),
      },
      {
        title: "音频",
        id: "data-audio",
        img: require("../assets/edit/base_audio.svg"),
      }]
    }
  },
  material: [],
  active: {
    base: {
      name: "液体",
      list: [{
        title: "管道",
        id: "active-pipe",
        img: require("../assets/edit/liquid_pipe.svg"),
      },
      {
        title: "水池",
        id: "active-pool",
        img: require("../assets/edit/liquid_sink.svg"),
      },
      ],
    },
    bar: {
      name: "提示",
      list: [{
        title: "进度条",
        id: "active-progressbar",
        img: require("../assets/edit/bar_progress.jpg"),
      },],
    },
  },
  image: [],
};

export const menu = {
  enable: false,
  pos: { x: 0, y: 0 },
  list: [{
    enable: true,
    show: true,
    title: "复制",
    // prompt: "ctrl+C",
    border: false,
  },
  {
    enable: true,
    show: true,
    title: "删除",
    // prompt: "delete",
    border: true,
  },
  {
    enable: true,
    show: true,
    title: "上移一层",
    // prompt: "ctrl+[",
    border: false,
  },
  {
    enable: true,
    show: true,
    title: "下移一层",
    // prompt: "ctrl+]",
    border: false,
  },
  {
    enable: true,
    show: true,
    title: "置于顶层",
    // prompt: "ctrl+shift+[",
    border: false,
  },
  {
    enable: true,
    show: true,
    title: "置于底层",
    // prompt: "ctrl+shift+]",
    border: true,
  },
  {
    enable: true,
    show: true,
    title: "锁定",
    // prompt: "ctrl+shift+L",
    border: false,
  },
  {
    enable: true,
    show: false,
    title: "解锁",
    // prompt: "ctrl+shift+L",
    border: false,
  },
  ],
};

export const textFields = {
  fontSize: [{
    key: "10",
    val: "10"
  }, {
    key: "大小（默认）",
    val: "12"
  }, {
    key: "12",
    val: "12"
  }, {
    key: "13",
    val: "13"
  }, {
    key: "14",
    val: "14"
  }, {
    key: "16",
    val: "16"
  }, {
    key: "18",
    val: "18"
  }, {
    key: "20",
    val: "20"
  }, {
    key: "24",
    val: "24"
  }, {
    key: "36",
    val: "36"
  }, {
    key: "48",
    val: "48"
  }, {
    key: "60",
    val: "60"
  }, {
    key: "72",
    val: "72"
  }, {
    key: "84",
    val: "84"
  }, {
    key: "96",
    val: "96"
  }, {
    key: "100",
    val: "100"
  }, {
    key: "200",
    val: "200"
  }, {
    key: "300",
    val: "300"
  }],
  fontFamily: [{
    key: "字体（默认）",
    val: "wryh"
  }, {
    key: "微软雅黑",
    val: "wryh"
  }, {
    key: "苹方",
    val: "pf"
  }],
  lineHeight: [{
    key: "行高（默认）",
    val: "1"
  }, {
    key: "1",
    val: "1"
  }, {
    key: "1.2",
    val: "1.2"
  }, {
    key: "1.5",
    val: "1.5"
  }, {
    key: "1.75",
    val: "1.75"
  }, {
    key: "2",
    val: "2"
  }, {
    key: "3",
    val: "3"
  }, {
    key: "4",
    val: "4"
  }, {
    key: "5",
    val: "5"
  }, {
    key: "自动",
    val: "0"
  }],
  textAlign: [{
    key: "对齐（默认）",
    val: "left"
  }, {
    key: "居左",
    val: "left"
  }, {
    key: "居中",
    val: "center"
  }, {
    key: "居右",
    val: "right"
  }]
};

export const control = {
  enable: false,
  item: null,
  value: "",
  log: {},
};

export const regPos = /^\d+(\.\d+)?$/;
export const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;

export function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
};

export const controlWayList = [ // 组件-控制 的方式列表
  { key: 0, name: '默认' },
  { key: 1, name: '按1松0' },
  { key: 2, name: '按0松1' },
  { key: 3, name: '按下置1' },
  { key: 4, name: '按下置0' },
  { key: 5, name: '按下取反' }
]